import { action, computed, observable } from 'mobx';

import { IFanclubFilters } from '../models/fanclub';
import { RootStore } from './rootStore';

export default class FanclubStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fanclubRegistry = new Map();
  @observable
  fanclubsFilters: IFanclubFilters | null = null;

  @computed get fanclubs() {
    return Array.from(this.fanclubRegistry.values())
      .sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
      .splice(0, 20);
  }

  @action setFanclubsFilters = (fanclubsFilters: IFanclubFilters) => {
    this.fanclubsFilters = fanclubsFilters;
  };
}
