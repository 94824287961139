import { action, computed, observable } from 'mobx';

import { IMemberFilters } from '../models/member';
import { RootStore } from './rootStore';

export default class MemberStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable memberRegistry = new Map();
  @observable
  membersFilters: IMemberFilters | null = null;

  @computed get members() {
    return Array.from(this.memberRegistry.values())
      .sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
      .splice(0, 20);
  }

  @action setMembersFilters = (membersFilters: IMemberFilters) => {
    this.membersFilters = membersFilters;
  };
}
