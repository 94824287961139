export interface IMember {
  fc_code: string;
  id: string;
  password: string;
  expires_at: string;
  expires_at_unix_time: number;
  created_at: string | null;
  updated_at: string | null;
}

export class MemberFormValues implements IMember {
  fc_code: string = '';
  id: string = '';
  password: string = '';
  expires_at: string = '';
  expires_at_unix_time: number = 0;
  created_at: string | null = null;
  updated_at: string | null = null;

  constructor(init?: IMember) {
    Object.assign(this, init);
  }
}

export interface IMemberFilters {
  fc_code: string;
  id: string;
}

export class MemberFiltersFormValues implements IMemberFilters {
  fc_code: string = '';
  id: string = '';

  constructor(init?: IMemberFilters) {
    Object.assign(this, init);
  }
}
