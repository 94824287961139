/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addFanclub = /* GraphQL */ `
  mutation AddFanclub($input: FanclubInput!) {
    addFanclub(input: $input) {
      code
      title
      description
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const updateFanclub = /* GraphQL */ `
  mutation UpdateFanclub($input: FanclubInput!) {
    updateFanclub(input: $input) {
      code
      title
      description
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const deleteFanclub = /* GraphQL */ `
  mutation DeleteFanclub($code: String) {
    deleteFanclub(code: $code) {
      code
      title
      description
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const addClient = /* GraphQL */ `
  mutation AddClient($input: ClientInput!) {
    addClient(input: $input) {
      code
      name
      secret_key
      created_at
      updated_at
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: ClientInput!) {
    updateClient(input: $input) {
      code
      name
      secret_key
      created_at
      updated_at
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient($code: String) {
    deleteClient(code: $code) {
      code
      name
      secret_key
      created_at
      updated_at
    }
  }
`;
export const addMember = /* GraphQL */ `
  mutation AddMember($input: MemberInput!) {
    addMember(input: $input) {
      id
      fc_code
      password
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember($input: MemberInput!) {
    updateMember(input: $input) {
      id
      fc_code
      password
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember($fc_code: String!, $id: String!) {
    deleteMember(fc_code: $fc_code, id: $id) {
      id
      fc_code
      password
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const addSkiyaki = /* GraphQL */ `
  mutation AddSkiyaki($input: SkiyakiInput!) {
    addSkiyaki(input: $input) {
      code
      authorize_endpoint
      token_endpoint
      check_member_endpoint
      client_id
      client_secret
      created_at
      updated_at
    }
  }
`;
export const updateSkiyaki = /* GraphQL */ `
  mutation UpdateSkiyaki($input: SkiyakiInput!) {
    updateSkiyaki(input: $input) {
      code
      authorize_endpoint
      token_endpoint
      check_member_endpoint
      client_id
      client_secret
      created_at
      updated_at
    }
  }
`;
export const deleteSkiyaki = /* GraphQL */ `
  mutation DeleteSkiyaki($code: String) {
    deleteSkiyaki(code: $code) {
      code
      authorize_endpoint
      token_endpoint
      check_member_endpoint
      client_id
      client_secret
      created_at
      updated_at
    }
  }
`;
