import { API, graphqlOperation } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Icon, Label, Modal, Segment, Table } from 'semantic-ui-react';

import { AllMemberByKeyQuery } from '../../api/adminAPI';
import { formatDateDisp } from '../../app/common/util/util';
import { IMember, MemberFiltersFormValues } from '../../app/models/member';
import { RootStoreContext } from '../../app/stores/rootStore';
import { deleteMember } from '../../graphql/mutations';
import { allMemberByKey } from '../../graphql/queries';

const csvData = [['fc_code', 'id', 'password', 'expires_at']];

const MemberList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { memberRegistry, members, membersFilters, setMembersFilters } = rootStore.memberStore;

  const csvInstance = useRef<any | null>(null);

  const [formValues, setFormValues] = useState(new MemberFiltersFormValues());
  const [submitting, setSubmitting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [targetRow, setTargetRow] = useState<IMember | null>(null);

  useEffect(() => {
    if (membersFilters !== null) {
      setFormValues(new MemberFiltersFormValues(membersFilters));
    }
  }, [membersFilters]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleLoadMembers = async () => {
    setSubmitting(true);
    setMembersFilters(formValues);
    // 一覧初期化
    memberRegistry.clear();
    if (formValues.id) {
      // キー検索（fc_code + id）
      try {
        const result = await API.graphql(
          graphqlOperation(allMemberByKey, { fc_code: formValues.fc_code, id: formValues.id }),
        );
        if ('data' in result && result.data) {
          const data = result.data as AllMemberByKeyQuery;
          if (data.allMemberByKey) {
            data.allMemberByKey.items.forEach((member) => {
              memberRegistry.set(member.id, member);
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    } else {
      // GSI検索（fc_code）
      try {
        const result = await API.graphql(graphqlOperation(allMemberByKey, { fc_code: formValues.fc_code }));
        if ('data' in result && result.data) {
          const data = result.data as AllMemberByKeyQuery;
          if (data.allMemberByKey) {
            data.allMemberByKey.items.forEach((member) => {
              memberRegistry.set(member.id, member);
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleDeleteClient = async (member: IMember) => {
    // Submit
    setSubmitting(true);
    try {
      // 削除
      await API.graphql(graphqlOperation(deleteMember, { fc_code: member.fc_code, id: member.id }));
      memberRegistry.delete(member.id);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleTemplateDownload = () => {
    if (csvInstance?.current?.link) {
      csvInstance.current.link.click();
    }
  };

  return (
    <Grid>
      <Grid.Column width={8}>
        <Label size="large" color="teal" basic>
          会員マスタ検索
        </Label>
        <Segment clearing>
          <Form size="small" onSubmit={handleLoadMembers}>
            <Form.Group widths="equal">
              <Form.Input
                required
                fluid
                label="FCコード"
                name="fc_code"
                value={formValues.fc_code}
                onChange={handleInputChange}
              />
              <Form.Input fluid label="会員ID" name="id" value={formValues.id} onChange={handleInputChange} />
            </Form.Group>
            <Button floated="right" type="submit" disabled={submitting} loading={submitting}>
              検索
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column width={16}>
        <Label size="large" color="red" basic>
          上位20件を表示
        </Label>
        <Button.Group floated="right">
          <Button content="Upload" primary as={Link} to={`/members/upload`} />
          <Button content="Download" secondary onClick={() => handleTemplateDownload()} />
          <CSVLink data={csvData} filename="members.csv" ref={csvInstance} />
        </Button.Group>
        <Table celled compact striped selectable size="small" fixed>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell width="1">FCコード</Table.HeaderCell>
              <Table.HeaderCell>会員ID</Table.HeaderCell>
              <Table.HeaderCell>パスワード</Table.HeaderCell>
              <Table.HeaderCell>有効期限日</Table.HeaderCell>
              <Table.HeaderCell>登録日</Table.HeaderCell>
              <Table.HeaderCell>更新日</Table.HeaderCell>
              <Table.HeaderCell>削除</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {members.map((member: IMember) => (
              <Table.Row key={member.id}>
                <Table.Cell>{member.fc_code}</Table.Cell>
                <Table.Cell>{member.id}</Table.Cell>
                <Table.Cell>{member.password}</Table.Cell>
                <Table.Cell singleLine>
                  {member.expires_at ? formatDateDisp(new Date(member.expires_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  {member.created_at ? formatDateDisp(new Date(member.created_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  {member.updated_at ? formatDateDisp(new Date(member.updated_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  <Modal
                    trigger={
                      <Button
                        content="削除"
                        color="red"
                        compact
                        onClick={() => {
                          setTargetRow(member);
                          setDeleteModalOpen(true);
                        }}
                      />
                    }
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    basic
                    size="small"
                  >
                    <Modal.Content>
                      <h3>削除してよろしいでしょうか？</h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="green"
                        inverted
                        floated="right"
                        loading={submitting}
                        onClick={() => {
                          targetRow && handleDeleteClient(targetRow).then(() => setDeleteModalOpen(false));
                        }}
                      >
                        <Icon name="checkmark" />
                        はい
                      </Button>
                      <Button color="red" inverted onClick={() => setDeleteModalOpen(false)}>
                        <Icon name="remove" />
                        いいえ
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid>
  );
};

export default observer(MemberList);
