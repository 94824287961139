import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Segment } from 'semantic-ui-react';

const TopPage = () => {
  return (
    <Segment inverted textAlign="center" vertical className={process.env.REACT_APP_ENV ? 'stgmasthead' : 'masthead'}>
      <Container text>
        <Header as="h2" inverted>
          {process.env.REACT_APP_ENV ? `${process.env.REACT_APP_ENV}認証Gateway管理画面` : '認証Gateway管理画面'}
        </Header>
        <Fragment>
          <Button.Group vertical>
            <Button as={Link} to="/clients" size="medium" inverted>
              クライアントマスタ
            </Button>
            <Button as={Link} to="/fanclubs" size="medium" inverted>
              ファンクラブマスタ
            </Button>
            <Button as={Link} to="/members" size="medium" inverted>
              会員マスタ
            </Button>
            <Button as={Link} to="/skiyakis" size="medium" inverted>
              SKIYAKI認証マスタ
            </Button>
          </Button.Group>
        </Fragment>
      </Container>
    </Segment>
  );
};

export default TopPage;
