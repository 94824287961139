import { Auth } from 'aws-amplify';
import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'semantic-ui-react';

import ClientForm from '../../features/clients/ClientForm';
import ClientList from '../../features/clients/ClientList';
import FanclubForm from '../../features/fanclubs/FanclubForm';
import FanclubList from '../../features/fanclubs/FanclubList';
import MemberList from '../../features/members/MemberList';
import MemberUpload from '../../features/members/MemberUpload';
import NavBar from '../../features/nav/NavBar';
import SkiyakiForm from '../../features/skiyakis/SkiyakiForm';
import SkiyakiList from '../../features/skiyakis/SkiyakiList';
import TopPage from '../../features/top/TopPage';
import { RootStoreContext } from '../stores/rootStore';
import NotFound from './NotFound';

const App: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { setCurrentUserEmail } = rootStore.authStore;

  const getCurrentUser = useCallback(async () => {
    // Cognitoユーザー取得
    const currentUser = await Auth.currentAuthenticatedUser();
    setCurrentUserEmail(currentUser.attributes.email);
  }, [setCurrentUserEmail]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <Fragment>
      <ToastContainer position="bottom-right" />
      <Route exact path="/" component={TopPage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <Fragment>
            <NavBar />
            <Container style={{ marginTop: '7em' }}>
              <Switch>
                <Route exact path="/clients" component={ClientList} />
                <Route path={['/clients/add', '/clients/update/:code']} component={ClientForm} />
                <Route exact path="/fanclubs" component={FanclubList} />
                <Route path={['/fanclubs/add', '/fanclubs/update/:code']} component={FanclubForm} />
                <Route exact path="/members" component={MemberList} />
                <Route path="/members/upload" component={MemberUpload} />
                <Route exact path="/skiyakis" component={SkiyakiList} />
                <Route path={['/skiyakis/add', '/skiyakis/update/:code']} component={SkiyakiForm} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default withRouter(App);
