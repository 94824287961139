import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';

import { AmplifySignOut } from '@aws-amplify/ui-react';

const NavBar: React.FC = () => {
  return (
    <Menu fixed="top" inverted className={process.env.REACT_APP_ENV && 'stg'}>
      <Container>
        <Menu.Item
          header
          exact
          name={process.env.REACT_APP_ENV ? `${process.env.REACT_APP_ENV}認証GW管理画面` : '認証GW管理画面'}
          as={NavLink}
          to="/"
        />
        <Menu.Item name="クライアントマスタ" as={NavLink} to="/clients" />
        <Menu.Item name="ファンクラブマスタ" as={NavLink} to="/fanclubs" />
        <Menu.Item name="会員マスタ" as={NavLink} to="/members" />
        <Menu.Item name="SKIYAKI認証マスタ" as={NavLink} to="/skiyakis" />
        <Menu.Item position="right">
          <AmplifySignOut />
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default NavBar;
