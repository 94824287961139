import { configure } from 'mobx';
import { createContext } from 'react';

import AuthStore from './authStore';
import ClientStore from './clientStore';
import FanclubStore from './fanclubStore';
import MemberStore from './memberStore';
import SkiyakiStore from './skiyakiStore';

configure({ enforceActions: 'always' });

export class RootStore {
  authStore: AuthStore;
  clientStore: ClientStore;
  fanclubStore: FanclubStore;
  memberStore: MemberStore;
  skiyakiStore: SkiyakiStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.clientStore = new ClientStore(this);
    this.fanclubStore = new FanclubStore(this);
    this.memberStore = new MemberStore(this);
    this.skiyakiStore = new SkiyakiStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
