import { API, graphqlOperation } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';

import { history } from '../..';
import { AddSkiyakiMutation, GetSkiyakiQuery, UpdateSkiyakiMutation } from '../../api/adminAPI';
import { ISkiyaki, SkiyakiFormValues } from '../../app/models/skiyaki';
import { RootStoreContext } from '../../app/stores/rootStore';
import { addSkiyaki, updateSkiyaki } from '../../graphql/mutations';
import { getSkiyaki } from '../../graphql/queries';

interface DetailParams {
  code: string;
}

const SkiyakiForm: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { skiyakiRegistry } = rootStore.skiyakiStore;

  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(new SkiyakiFormValues());
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);

  const handleLoadSkiyaki = useCallback(async (code) => {
    // キー検索
    try {
      const result = await API.graphql(graphqlOperation(getSkiyaki, { code: code }));
      if ('data' in result && result.data) {
        const data = result.data as GetSkiyakiQuery;
        if (data.getSkiyaki) {
          setFormValues(new SkiyakiFormValues(data.getSkiyaki as ISkiyaki));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (match.params.code) {
      let Skiyaki = skiyakiRegistry.get(match.params.code);
      if (!Skiyaki) {
        handleLoadSkiyaki(match.params.code);
      } else {
        setFormValues(new SkiyakiFormValues(Skiyaki));
      }
    }
  }, [match.params.code, handleLoadSkiyaki, skiyakiRegistry]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleModalOpen = async () => {
    // ModalOpen
    setUpsertModalOpen(true);
  };

  const handleUpsert = async () => {
    // Submit
    setSubmitting(true);
    try {
      if (!match.params.code) {
        // 登録
        const result = await API.graphql(graphqlOperation(addSkiyaki, { input: formValues }));
        if ('data' in result && result.data) {
          const data = result.data as AddSkiyakiMutation;
          if (data.addSkiyaki) {
            skiyakiRegistry.set(data.addSkiyaki.code, data.addSkiyaki);
          }
        }
      } else {
        // 編集
        const result = await API.graphql(graphqlOperation(updateSkiyaki, { input: formValues }));
        if ('data' in result && result.data) {
          const data = result.data as UpdateSkiyakiMutation;
          if (data.updateSkiyaki) {
            skiyakiRegistry.set(data.updateSkiyaki.code, data.updateSkiyaki);
          }
        }
      }

      history.push('/skiyakis');
    } catch (error) {
      console.log(error);
      toast.error('同一のSKIYAKI認証コードが既に登録されています。');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment clearing>
          <Header icon="keyboard" content={!match.params.code ? 'SKIYAKI認証登録' : 'SKIYAKI認証編集'} />
          <Form size="small" onSubmit={handleModalOpen}>
            <Form.Input
              required
              fluid
              disabled={!!match.params.code}
              label="コード"
              name="code"
              value={formValues.code}
              onChange={handleInputChange}
            />
            <Form.Input
              required
              fluid
              label="認証エンドポイント"
              name="authorize_endpoint"
              value={formValues.authorize_endpoint}
              onChange={handleInputChange}
            />
            <Form.Input
              required
              fluid
              label="アクセストークン取得エンドポイント"
              name="token_endpoint"
              value={formValues.token_endpoint}
              onChange={handleInputChange}
            />
            <Form.Input
              required
              fluid
              label="会員状態チェックエンドポイント"
              name="check_member_endpoint"
              value={formValues.check_member_endpoint}
              onChange={handleInputChange}
            />
            <Form.Input
              required
              fluid
              label="クライアントID"
              name="client_id"
              value={formValues.client_id}
              onChange={handleInputChange}
            />
            <Form.Input
              required
              fluid
              label="クライアントシークレット"
              name="client_secret"
              value={formValues.client_secret}
              onChange={handleInputChange}
            />
            <Modal
              trigger={<Button disabled={submitting} floated="right" positive type="submit" content="登録" />}
              open={upsertModalOpen}
              onClose={() => setUpsertModalOpen(false)}
              basic
              size="small"
            >
              <Modal.Content>
                <h3>SKIYAKI認証{!match.params.code ? '登録' : '編集'}してよろしいでしょうか？</h3>
              </Modal.Content>
              <Modal.Actions>
                <Button color="green" inverted floated="right" loading={submitting} onClick={() => handleUpsert()}>
                  <Icon name="checkmark" />
                  はい
                </Button>
                <Button color="red" inverted onClick={() => setUpsertModalOpen(false)}>
                  <Icon name="remove" />
                  いいえ
                </Button>
              </Modal.Actions>
            </Modal>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default observer(SkiyakiForm);
