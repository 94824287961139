import Amplify from 'aws-amplify';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Button, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';

import { history } from '../..';

const FanclubUpload: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [uploadfile, setUploadfile] = useState<File | null>(null);
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadfile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dropzoneStyles = {
    border: 'dashed 3px #eee',
    borderColor: '#eee',
    borderRedius: '5px',
    paddingTop: '30px',
    marginBottom: '10px',
    textAlign: 'center' as 'center',
    height: '200px',
  };

  const dropzoneActive = {
    borderColor: 'green',
  };

  const handleModalOpen = async () => {
    // Validation
    if (uploadfile?.name !== 'members.csv') {
      toast.error('アップロードするCSVファイル名は「members.csv」にしてください。');
      return;
    }

    // ModalOpen
    setUpsertModalOpen(true);
  };

  const handleUpload = async () => {
    // Submit
    setSubmitting(true);
    try {
      await Amplify.Storage.put(uploadfile?.name, uploadfile);
      toast.info('CSVファイルをインポートしました。しばらく時間をおいて、会員検索を行ってください。');
      history.push('/members');
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment clearing>
          <Header icon="file" content="会員CSVアップロード" />
          <p>同一のFCコード+会員IDをアップロードした場合、データが上書きされます。</p>
          <p>有効期限日(expires_at) は YYYY-MM-DD の形式で入力してください。</p>
          <p>選択されたファイル：{uploadfile ? uploadfile.name : null}</p>
          <div {...getRootProps()} style={isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles}>
            <input {...getInputProps()} />
            <Icon name="upload" size="huge" />
            <Header content="CSVファイルをドロップしてください" />
          </div>
          <Modal
            trigger={
              <Button
                disabled={submitting}
                floated="right"
                positive
                type="submit"
                content="登録"
                onClick={() => handleModalOpen()}
              />
            }
            open={upsertModalOpen}
            onClose={() => setUpsertModalOpen(false)}
            basic
            size="small"
          >
            <Modal.Content>
              <h3>CSVファイルをアップロードしてよろしいでしょうか？</h3>
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" inverted floated="right" loading={submitting} onClick={() => handleUpload()}>
                <Icon name="checkmark" />
                はい
              </Button>
              <Button color="red" inverted onClick={() => setUpsertModalOpen(false)}>
                <Icon name="remove" />
                いいえ
              </Button>
            </Modal.Actions>
          </Modal>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default FanclubUpload;
