export interface IClient {
  code: string;
  name: string;
  secret_key: string;
  created_at: string | null;
  updated_at: string | null;
}

export class ClientFormValues implements IClient {
  code: string = '';
  name: string = '';
  secret_key: string = '';
  created_at: string | null = null;
  updated_at: string | null = null;

  constructor(init?: IClient) {
    Object.assign(this, init);
  }
}

export interface IClientFilters {
  code: string;
}

export class ClientFiltersFormValues implements IClientFilters {
  code: string = '';

  constructor(init?: IClientFilters) {
    Object.assign(this, init);
  }
}
