import { API, graphqlOperation } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Icon, Label, Modal, Segment, Table } from 'semantic-ui-react';

import { AllFanclubByKeyQuery, AllFanclubQuery } from '../../api/adminAPI';
import { formatDateDisp } from '../../app/common/util/util';
import { FanclubFiltersFormValues, IFanclub } from '../../app/models/fanclub';
import { RootStoreContext } from '../../app/stores/rootStore';
import { deleteFanclub } from '../../graphql/mutations';
import { allFanclub, allFanclubByKey } from '../../graphql/queries';

const FanclubList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { fanclubRegistry, fanclubs, fanclubsFilters, setFanclubsFilters } = rootStore.fanclubStore;
  const [formValues, setFormValues] = useState(new FanclubFiltersFormValues());
  const [submitting, setSubmitting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [targetRow, setTargetRow] = useState<IFanclub | null>(null);

  useEffect(() => {
    if (fanclubsFilters !== null) {
      setFormValues(new FanclubFiltersFormValues(fanclubsFilters));
    }
  }, [fanclubsFilters]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleLoadFanclubs = async () => {
    setSubmitting(true);
    setFanclubsFilters(formValues);
    // 一覧初期化
    fanclubRegistry.clear();
    if (formValues.code) {
      // キー検索
      try {
        const result = await API.graphql(graphqlOperation(allFanclubByKey, { code: formValues.code }));
        if ('data' in result && result.data) {
          const data = result.data as AllFanclubByKeyQuery;
          if (data.allFanclubByKey) {
            data.allFanclubByKey.items.forEach((fanclub) => {
              fanclubRegistry.set(fanclub.code, fanclub);
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    } else {
      // 全件検索
      try {
        const result = await API.graphql(graphqlOperation(allFanclub));
        if ('data' in result && result.data) {
          const data = result.data as AllFanclubQuery;
          if (data.allFanclub) {
            data.allFanclub.items.forEach((fanclub) => {
              fanclubRegistry.set(fanclub.code, fanclub);
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleDeleteFanclub = async (fanclub: IFanclub) => {
    // Submit
    setSubmitting(true);
    try {
      // 削除
      await API.graphql(graphqlOperation(deleteFanclub, { code: fanclub.code }));
      fanclubRegistry.delete(fanclub.code);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={8}>
        <Label size="large" color="teal" basic>
          ファンクラブマスタ検索
        </Label>
        <Segment clearing>
          <Form size="small" onSubmit={handleLoadFanclubs}>
            <Form.Input fluid label="コード" name="code" value={formValues.code} onChange={handleInputChange} />
            <Button floated="right" type="submit" disabled={submitting} loading={submitting}>
              検索
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column width={16}>
        <Label size="large" color="red" basic>
          上位20件を表示
        </Label>
        <Button floated="right" positive content="新規登録" as={Link} to={`/fanclubs/add`} />
        <Table celled compact striped selectable size="small" fixed>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell width="1">コード</Table.HeaderCell>
              <Table.HeaderCell>タイトル</Table.HeaderCell>
              <Table.HeaderCell>説明</Table.HeaderCell>
              <Table.HeaderCell>有効期限日</Table.HeaderCell>
              <Table.HeaderCell>登録日</Table.HeaderCell>
              <Table.HeaderCell>更新日</Table.HeaderCell>
              <Table.HeaderCell>編集</Table.HeaderCell>
              <Table.HeaderCell>削除</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fanclubs.map((fanclub: IFanclub) => (
              <Table.Row key={fanclub.code}>
                <Table.Cell>{fanclub.code}</Table.Cell>
                <Table.Cell>{fanclub.title}</Table.Cell>
                <Table.Cell>{fanclub.description}</Table.Cell>
                <Table.Cell singleLine>
                  {fanclub.expires_at ? formatDateDisp(new Date(fanclub.expires_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  {fanclub.created_at ? formatDateDisp(new Date(fanclub.created_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  {fanclub.updated_at ? formatDateDisp(new Date(fanclub.updated_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  <Button content="編集" color="olive" compact as={Link} to={`/fanclubs/update/${fanclub.code}`} />
                </Table.Cell>
                <Table.Cell singleLine>
                  <Modal
                    trigger={
                      <Button
                        content="削除"
                        color="red"
                        compact
                        onClick={() => {
                          setTargetRow(fanclub);
                          setDeleteModalOpen(true);
                        }}
                      />
                    }
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    basic
                    size="small"
                  >
                    <Modal.Content>
                      <h3>削除してよろしいでしょうか？</h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="green"
                        inverted
                        floated="right"
                        loading={submitting}
                        onClick={() => {
                          targetRow && handleDeleteFanclub(targetRow).then(() => setDeleteModalOpen(false));
                        }}
                      >
                        <Icon name="checkmark" />
                        はい
                      </Button>
                      <Button color="red" inverted onClick={() => setDeleteModalOpen(false)}>
                        <Icon name="remove" />
                        いいえ
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid>
  );
};

export default observer(FanclubList);
