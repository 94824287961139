import { action, computed, observable } from 'mobx';

import { IClientFilters } from '../models/client';
import { RootStore } from './rootStore';

export default class ClientStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable clientRegistry = new Map();
  @observable
  clientsFilters: IClientFilters | null = null;

  @computed get clients() {
    return Array.from(this.clientRegistry.values())
      .sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
      .splice(0, 20);
  }

  @action setClientsFilters = (clientsFilters: IClientFilters) => {
    this.clientsFilters = clientsFilters;
  };
}
