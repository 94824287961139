import { action, computed, observable } from 'mobx';

import { ISkiyakiFilters } from '../models/skiyaki';
import { RootStore } from './rootStore';

export default class SkiyakiStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable skiyakiRegistry = new Map();
  @observable
  skiyakisFilters: ISkiyakiFilters | null = null;

  @computed get skiyakis() {
    return Array.from(this.skiyakiRegistry.values())
      .sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
      .splice(0, 20);
  }

  @action setSkiyakisFilters = (skiyakisFilters: ISkiyakiFilters) => {
    this.skiyakisFilters = skiyakisFilters;
  };
}
