import { addDays, format } from 'date-fns';
import ja from 'date-fns/locale/ja';

export const addDay = (date: Date, addCount: number) => {
  return addDays(date, addCount);
};

export const formatDateDisp = (date: Date) => {
  return format(new Date(date), 'yyyy-MM-dd', {
    locale: ja,
  });
};
