import { API, graphqlOperation } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';

import { history } from '../..';
import { AddClientMutation, GetClientQuery, UpdateClientMutation } from '../../api/adminAPI';
import { ClientFormValues, IClient } from '../../app/models/client';
import { RootStoreContext } from '../../app/stores/rootStore';
import { addClient, updateClient } from '../../graphql/mutations';
import { getClient } from '../../graphql/queries';

interface DetailParams {
  code: string;
}

const ClientForm: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { clientRegistry } = rootStore.clientStore;

  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(new ClientFormValues());
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);

  const handleLoadClient = useCallback(async (code) => {
    // キー検索
    try {
      const result = await API.graphql(graphqlOperation(getClient, { code: code }));
      if ('data' in result && result.data) {
        const data = result.data as GetClientQuery;
        if (data.getClient) {
          setFormValues(new ClientFormValues(data.getClient as IClient));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (match.params.code) {
      let client = clientRegistry.get(match.params.code);
      if (!client) {
        handleLoadClient(match.params.code);
      } else {
        setFormValues(new ClientFormValues(client));
      }
    }
  }, [match.params.code, handleLoadClient, clientRegistry]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleModalOpen = async () => {
    // Validation
    formValues.secret_key = formValues.secret_key.trim();
    if (formValues.secret_key.length !== 32 || !/^[\x20-\x7e]*$/.test(formValues.secret_key)) {
      toast.error('シークレットキーは半角32文字で作成してください。');
      return;
    }
    // ModalOpen
    setUpsertModalOpen(true);
  };

  const handleUpsert = async () => {
    // Submit
    setSubmitting(true);
    try {
      if (!match.params.code) {
        // 登録
        const result = await API.graphql(graphqlOperation(addClient, { input: formValues }));
        if ('data' in result && result.data) {
          const data = result.data as AddClientMutation;
          if (data.addClient) {
            clientRegistry.set(data.addClient.code, data.addClient);
          }
        }
      } else {
        // 編集
        const result = await API.graphql(graphqlOperation(updateClient, { input: formValues }));
        if ('data' in result && result.data) {
          const data = result.data as UpdateClientMutation;
          if (data.updateClient) {
            clientRegistry.set(data.updateClient.code, data.updateClient);
          }
        }
      }

      history.push('/clients');
    } catch (error) {
      console.log(error);
      toast.error('同一のクライアントコードが既に登録されています。');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment clearing>
          <Header icon="keyboard" content={!match.params.code ? 'クライアント登録' : 'クライアント編集'} />
          <Form size="small" onSubmit={handleModalOpen}>
            <Form.Input
              required
              fluid
              disabled={!!match.params.code}
              label="コード"
              name="code"
              value={formValues.code}
              onChange={handleInputChange}
            />
            <Form.Input required fluid label="名称" name="name" value={formValues.name} onChange={handleInputChange} />
            <Form.Input
              required
              fluid
              label="シークレットキー"
              name="secret_key"
              value={formValues.secret_key}
              onChange={handleInputChange}
            />
            <Modal
              trigger={<Button disabled={submitting} floated="right" positive type="submit" content="登録" />}
              open={upsertModalOpen}
              onClose={() => setUpsertModalOpen(false)}
              basic
              size="small"
            >
              <Modal.Content>
                <h3>クライアント{!match.params.code ? '登録' : '編集'}してよろしいでしょうか？</h3>
              </Modal.Content>
              <Modal.Actions>
                <Button color="green" inverted floated="right" loading={submitting} onClick={() => handleUpsert()}>
                  <Icon name="checkmark" />
                  はい
                </Button>
                <Button color="red" inverted onClick={() => setUpsertModalOpen(false)}>
                  <Icon name="remove" />
                  いいえ
                </Button>
              </Modal.Actions>
            </Modal>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default observer(ClientForm);
