/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allFanclub = /* GraphQL */ `
  query AllFanclub($count: Int, $nextToken: String) {
    allFanclub(count: $count, nextToken: $nextToken) {
      items {
        code
        title
        description
        expires_at
        expires_at_unix_time
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const allFanclubByKey = /* GraphQL */ `
  query AllFanclubByKey($code: String!, $count: Int, $nextToken: String) {
    allFanclubByKey(code: $code, count: $count, nextToken: $nextToken) {
      items {
        code
        title
        description
        expires_at
        expires_at_unix_time
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const getFanclub = /* GraphQL */ `
  query GetFanclub($code: String!) {
    getFanclub(code: $code) {
      code
      title
      description
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const allClient = /* GraphQL */ `
  query AllClient($count: Int, $nextToken: String) {
    allClient(count: $count, nextToken: $nextToken) {
      items {
        code
        name
        secret_key
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const allClientByKey = /* GraphQL */ `
  query AllClientByKey($code: String!, $count: Int, $nextToken: String) {
    allClientByKey(code: $code, count: $count, nextToken: $nextToken) {
      items {
        code
        name
        secret_key
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($code: String!) {
    getClient(code: $code) {
      code
      name
      secret_key
      created_at
      updated_at
    }
  }
`;
export const allMember = /* GraphQL */ `
  query AllMember($count: Int, $nextToken: String) {
    allMember(count: $count, nextToken: $nextToken) {
      items {
        id
        fc_code
        password
        expires_at
        expires_at_unix_time
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const allMemberByKey = /* GraphQL */ `
  query AllMemberByKey(
    $fc_code: String!
    $id: String
    $count: Int
    $nextToken: String
  ) {
    allMemberByKey(
      fc_code: $fc_code
      id: $id
      count: $count
      nextToken: $nextToken
    ) {
      items {
        id
        fc_code
        password
        expires_at
        expires_at_unix_time
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($fc_code: String!, $id: String!) {
    getMember(fc_code: $fc_code, id: $id) {
      id
      fc_code
      password
      expires_at
      expires_at_unix_time
      created_at
      updated_at
    }
  }
`;
export const allSkiyaki = /* GraphQL */ `
  query AllSkiyaki($count: Int, $nextToken: String) {
    allSkiyaki(count: $count, nextToken: $nextToken) {
      items {
        code
        authorize_endpoint
        token_endpoint
        check_member_endpoint
        client_id
        client_secret
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const allSkiyakiByKey = /* GraphQL */ `
  query AllSkiyakiByKey($code: String!, $count: Int, $nextToken: String) {
    allSkiyakiByKey(code: $code, count: $count, nextToken: $nextToken) {
      items {
        code
        authorize_endpoint
        token_endpoint
        check_member_endpoint
        client_id
        client_secret
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const getSkiyaki = /* GraphQL */ `
  query GetSkiyaki($code: String!) {
    getSkiyaki(code: $code) {
      code
      authorize_endpoint
      token_endpoint
      check_member_endpoint
      client_id
      client_secret
      created_at
      updated_at
    }
  }
`;
