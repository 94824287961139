export interface IFanclub {
  code: string;
  title: string;
  description: string;
  expires_at: string;
  expires_at_unix_time: number;
  created_at: string | null;
  updated_at: string | null;
}

export class FanclubFormValues implements IFanclub {
  code: string = '';
  title: string = '';
  description: string = '';
  expires_at: string = '';
  expires_at_unix_time: number = 0;
  created_at: string | null = null;
  updated_at: string | null = null;

  constructor(init?: IFanclub) {
    Object.assign(this, init);
  }
}

export interface IFanclubFilters {
  code: string;
}

export class FanclubFiltersFormValues implements IFanclubFilters {
  code: string = '';

  constructor(init?: IFanclubFilters) {
    Object.assign(this, init);
  }
}
