import { action, observable } from 'mobx';

import { RootStore } from './rootStore';

export default class AuthStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable
  currentUserEmail: string | null = null;

  @action setCurrentUserEmail = (email: string) => {
    this.currentUserEmail = email;
  };
}
