import { API, graphqlOperation } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Icon, Label, Modal, Segment, Table } from 'semantic-ui-react';

import { AllClientByKeyQuery, AllClientQuery } from '../../api/adminAPI';
import { formatDateDisp } from '../../app/common/util/util';
import { ClientFiltersFormValues, IClient } from '../../app/models/client';
import { RootStoreContext } from '../../app/stores/rootStore';
import { deleteClient } from '../../graphql/mutations';
import { allClient, allClientByKey } from '../../graphql/queries';

const ClientList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { clientRegistry, clients, clientsFilters, setClientsFilters } = rootStore.clientStore;
  const [formValues, setFormValues] = useState(new ClientFiltersFormValues());
  const [submitting, setSubmitting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [targetRow, setTargetRow] = useState<IClient | null>(null);

  useEffect(() => {
    if (clientsFilters !== null) {
      setFormValues(new ClientFiltersFormValues(clientsFilters));
    }
  }, [clientsFilters]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleLoadClients = async () => {
    setSubmitting(true);
    setClientsFilters(formValues);
    // 一覧初期化
    clientRegistry.clear();
    if (formValues.code) {
      // キー検索
      try {
        const result = await API.graphql(graphqlOperation(allClientByKey, { code: formValues.code }));
        if ('data' in result && result.data) {
          const data = result.data as AllClientByKeyQuery;
          if (data.allClientByKey) {
            data.allClientByKey.items.forEach((client) => {
              clientRegistry.set(client.code, client);
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    } else {
      // 全件検索
      try {
        const result = await API.graphql(graphqlOperation(allClient));
        if ('data' in result && result.data) {
          const data = result.data as AllClientQuery;
          if (data.allClient) {
            data.allClient.items.forEach((client) => {
              clientRegistry.set(client.code, client);
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleDeleteClient = async (client: IClient) => {
    // Submit
    setSubmitting(true);
    try {
      // 削除
      await API.graphql(graphqlOperation(deleteClient, { code: client.code }));
      clientRegistry.delete(client.code);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={8}>
        <Label size="large" color="teal" basic>
          クライアントマスタ検索
        </Label>
        <Segment clearing>
          <Form size="small" onSubmit={handleLoadClients}>
            <Form.Input fluid label="コード" name="code" value={formValues.code} onChange={handleInputChange} />
            <Button floated="right" type="submit" disabled={submitting} loading={submitting}>
              検索
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column width={16}>
        <Label size="large" color="red" basic>
          上位20件を表示
        </Label>
        <Button floated="right" positive content="新規登録" as={Link} to={`/clients/add`} />
        <Table celled compact striped selectable size="small" fixed>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell width="1">コード</Table.HeaderCell>
              <Table.HeaderCell>名称</Table.HeaderCell>
              <Table.HeaderCell>シークレットキー</Table.HeaderCell>
              <Table.HeaderCell>登録日</Table.HeaderCell>
              <Table.HeaderCell>更新日</Table.HeaderCell>
              <Table.HeaderCell>編集</Table.HeaderCell>
              <Table.HeaderCell>削除</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clients.map((client: IClient) => (
              <Table.Row key={client.code}>
                <Table.Cell>{client.code}</Table.Cell>
                <Table.Cell>{client.name}</Table.Cell>
                <Table.Cell>{client.secret_key}</Table.Cell>
                <Table.Cell singleLine>
                  {client.created_at ? formatDateDisp(new Date(client.created_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  {client.updated_at ? formatDateDisp(new Date(client.updated_at!)) : undefined}
                </Table.Cell>
                <Table.Cell singleLine>
                  <Button content="編集" color="olive" compact as={Link} to={`/clients/update/${client.code}`} />
                </Table.Cell>
                <Table.Cell singleLine>
                  <Modal
                    trigger={
                      <Button
                        content="削除"
                        color="red"
                        compact
                        onClick={() => {
                          setTargetRow(client);
                          setDeleteModalOpen(true);
                        }}
                      />
                    }
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    basic
                    size="small"
                  >
                    <Modal.Content>
                      <h3>削除してよろしいでしょうか？</h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="green"
                        inverted
                        floated="right"
                        loading={submitting}
                        onClick={() => {
                          targetRow && handleDeleteClient(targetRow).then(() => setDeleteModalOpen(false));
                        }}
                      >
                        <Icon name="checkmark" />
                        はい
                      </Button>
                      <Button color="red" inverted onClick={() => setDeleteModalOpen(false)}>
                        <Icon name="remove" />
                        いいえ
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid>
  );
};

export default observer(ClientList);
