import { API, graphqlOperation } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';

import { history } from '../..';
import { AddFanclubMutation, GetFanclubQuery, UpdateFanclubMutation } from '../../api/adminAPI';
import CustomInput from '../../app/common/form/CustomInput';
import { addDay } from '../../app/common/util/util';
import { FanclubFormValues, IFanclub } from '../../app/models/fanclub';
import { RootStoreContext } from '../../app/stores/rootStore';
import { addFanclub, updateFanclub } from '../../graphql/mutations';
import { getFanclub } from '../../graphql/queries';

interface DetailParams {
  code: string;
}

const FanclubForm: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { fanclubRegistry } = rootStore.fanclubStore;

  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(new FanclubFormValues());
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);

  const handleLoadFanclub = useCallback(async (code) => {
    // キー検索
    try {
      const result = await API.graphql(graphqlOperation(getFanclub, { code: code }));
      if ('data' in result && result.data) {
        const data = result.data as GetFanclubQuery;
        if (data.getFanclub) {
          setFormValues(new FanclubFormValues(data.getFanclub as IFanclub));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (match.params.code) {
      let fanclub = fanclubRegistry.get(match.params.code);
      if (!fanclub) {
        handleLoadFanclub(match.params.code);
      } else {
        setFormValues(new FanclubFormValues(fanclub));
      }
    }
  }, [match.params.code, handleLoadFanclub, fanclubRegistry]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleTextAreaChange = (event: FormEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDatePickerChange = (name: string, date: any) => {
    date && setFormValues({ ...formValues, [name]: date.toISOString() });
  };

  const handleModalOpen = async () => {
    // Validation
    if (!formValues.expires_at) {
      toast.error('有効期限日は必須入力です。');
      return;
    }
    // ModalOpen
    setUpsertModalOpen(true);
  };

  const handleUpsert = async () => {
    // 有効期限日からTTLを算出
    formValues.expires_at_unix_time = Math.round(addDay(new Date(formValues.expires_at), 1).getTime() / 1000);

    // Submit
    setSubmitting(true);
    try {
      if (!match.params.code) {
        // 登録
        const result = await API.graphql(graphqlOperation(addFanclub, { input: formValues }));
        if ('data' in result && result.data) {
          const data = result.data as AddFanclubMutation;
          if (data.addFanclub) {
            fanclubRegistry.set(data.addFanclub.code, data.addFanclub);
          }
        }
      } else {
        // 編集
        const result = await API.graphql(graphqlOperation(updateFanclub, { input: formValues }));
        if ('data' in result && result.data) {
          const data = result.data as UpdateFanclubMutation;
          if (data.updateFanclub) {
            fanclubRegistry.set(data.updateFanclub.code, data.updateFanclub);
          }
        }
      }

      history.push('/fanclubs');
    } catch (error) {
      console.log(error);
      toast.error('同一のファンクラブコードが既に登録されています。');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment clearing>
          <Header icon="keyboard" content={!match.params.code ? 'ファンクラブ登録' : 'ファンクラブ編集'} />
          <Form size="small" onSubmit={handleModalOpen}>
            <Form.Input
              required
              fluid
              disabled={!!match.params.code}
              label="コード"
              name="code"
              value={formValues.code}
              onChange={handleInputChange}
            />
            <Form.TextArea
              required
              rows="5"
              label="タイトル"
              name="title"
              value={formValues.title}
              onChange={handleTextAreaChange}
            />
            <Form.TextArea
              rows="10"
              label="説明"
              name="description"
              value={formValues.description ? formValues.description : undefined}
              onChange={handleTextAreaChange}
            />
            <Form.Field required>
              <label>有効期限日</label>
              <DatePicker
                locale="ja"
                dateFormat="yyyy-MM-dd"
                selected={formValues.expires_at ? new Date(formValues.expires_at) : undefined}
                onChange={(date) => handleDatePickerChange('expires_at', date)}
                customInput={<CustomInput />}
                minDate={new Date()}
              />
            </Form.Field>
            <Modal
              trigger={<Button disabled={submitting} floated="right" positive type="submit" content="登録" />}
              open={upsertModalOpen}
              onClose={() => setUpsertModalOpen(false)}
              basic
              size="small"
            >
              <Modal.Content>
                <h3>ファンクラブ{!match.params.code ? '登録' : '編集'}してよろしいでしょうか？</h3>
              </Modal.Content>
              <Modal.Actions>
                <Button color="green" inverted floated="right" loading={submitting} onClick={() => handleUpsert()}>
                  <Icon name="checkmark" />
                  はい
                </Button>
                <Button color="red" inverted onClick={() => setUpsertModalOpen(false)}>
                  <Icon name="remove" />
                  いいえ
                </Button>
              </Modal.Actions>
            </Modal>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default observer(FanclubForm);
