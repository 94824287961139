export interface ISkiyaki {
  code: string;
  authorize_endpoint: string;
  token_endpoint: string;
  check_member_endpoint: string;
  client_id: string;
  client_secret: string;
  created_at: string | null;
  updated_at: string | null;
}

export class SkiyakiFormValues implements ISkiyaki {
  code: string = '';
  authorize_endpoint: string = '';
  token_endpoint: string = '';
  check_member_endpoint: string = '';
  client_id: string = '';
  client_secret: string = '';
  created_at: string | null = null;
  updated_at: string | null = null;

  constructor(init?: ISkiyaki) {
    Object.assign(this, init);
  }
}

export interface ISkiyakiFilters {
  code: string;
}

export class SkiyakiFiltersFormValues implements ISkiyakiFilters {
  code: string = '';

  constructor(init?: ISkiyakiFilters) {
    Object.assign(this, init);
  }
}
